body {
  background: #fff;
  color: #7D8091;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

a:active, a:hover {
  text-decoration: none;
}

strong {
  font-weight: 500;
}

h1,h2,h3,h4,h5,h6 {
  color: #263B57;
  margin: 0;
  padding: 0;
}

h1, .h1 {
  font-size: 3rem;
  font-weight: 300;
}

h2, .h2 {
  font-size: 2.25rem;
  font-weight: 500;
}

h3, .h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1rem;
}

.display-1 {
  font-size: 4rem;
  font-weight: 300;
}

.lead {
  font-size: 1.5rem;
}

.btn-primary {
  border-radius: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.header-transparent
.navbar:not(.sticky):not(.navbar-collapsed) {
  border: 0;
  background-color: transparent!important;
  box-shadow: none;
}

.navbar.sticky {
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .2s ease-in-out;
  border-top: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2)
}

.navbar {
  padding: 15px 0;
}

.navbar-nav {
  flex-direction: inherit;
}

.share-link {
  margin: 0 0 0 10px;
}

.share-link a {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid;
  border-color: #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease 0s;
}

.share-link a:hover {
  transform: scale(1.2);
}

.navbar-light .share-link a {
  border-color: #FF2D55;
  color: #FF2D55;
}

.section {
  border-bottom: 1px solid #EFEFF4;
  padding: 90px 0;
}

.bg-hero {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.bg-hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-image: url(../img/travly-cover.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-hero:after {
  content: "";
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 15px;
  z-index: 0;
  background-image: url(../img/arrow-down.svg);
  background-position: center 50%;
  background-repeat: no-repeat;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}

.public-profile {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 10%;
  z-index: 1;
}

.map-gui {
  position: relative;
  width: 100%;
  height: 600px;
  background-image: url(../img/sample-map-gui.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
}

.location {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  max-width: 100%;
}

.map-pin {
  display: inline-block;
  width: 20px;
  height: 24px;
  background-image: url(../img/map-pin.svg);
  margin-right: 10px;
}

.item {
  break-inside: avoid;
  box-sizing: border-box;
  padding: 12px;
}

.item figure {
  margin: 0;
}

.item .img-fluid {
  border-radius: 8px;
}

.img-holder {
  position: relative;
}

.img-holder:not(:hover) .img-overlay {
  visibility: hidden;
  opacity: 0;
}

.img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.25);
  border-radius: 8px;
  pointer-events: none;
  transition: opacity .15s ease-in-out,visibility .15s ease-in-out;
}

.img-share {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 32px;
  pointer-events: none;
  display: -webkit-box;
  display: flex;
}

.img-profile {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  pointer-events: none;
  display: -webkit-box;
  display: flex;
}

.social-list-item {
  text-align: center;
  margin-bottom: 2rem;
}

.btn-social-share {
  border-radius: 3.5rem;
  font-weight: 500;
  padding: 1rem 2rem;
  width: 75%;
}

.btn-facebook, .btn-facebook:hover {
  background-color: #3B5998;
  color: #fff;
}

.btn-twitter, .btn-twitter:hover {
  background-color: #1DA1F2;
  color: #fff;
}

.profile-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.profile-name {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.img-icon {
  pointer-events: auto;
  cursor: pointer;
  background-color: hsla(0,0%,100%,.85);
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0,0,0,.04);
  transition: all .2s ease-in-out;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  padding: 0 12px;
}

.img-icon:hover {
  background-color: #fff;
}

.img-icon > i {
  color: #777;
}

.masonry {
  column-count: 3;
  column-gap: 0;
}

.footer {
  background-color: #FF2D55;
  padding: 80px 0;
}

.footer a {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .display-1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .display-1 {
    font-size: 2.75rem;
  }
  .lead {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .masonry {
    column-count: 2;
  }
}
