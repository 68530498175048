body {
  background: #fff;
  color: #7D8091;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  -webkit-transition: all 200ms ease 0s;
  transition: all 200ms ease 0s;
}

a:hover {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6 {
  color: #263B57 !important;
  margin: 0;
  padding: 0;
}

h1, .h1 {
  font-size: 3rem;
  font-weight: 300;
}

h2, .h2 {
  font-size: 2.25rem;
  font-weight: 300;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1rem;
}

.strong, strong {
  font-weight: 500;
}

.small, small {
  font-size: 85% !important;
  line-height: 1.8;
}

.text-primary {
  color: #263B57 !important;
}

.text-secondary {
  color: #7D8091;
}

.btn {
  font-size: .85rem;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.btn-lg {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: #FF2D55;
  border-color: #FF2D55;
}

.btn-outline-primary {
  border-color: #FF2D55;
  color: #FF2D55;
}

.btn-primary:hover {
  box-shadow: 0 4px 17px rgba(30,32,34,0.10);
  background-color: #fff;
  border-color: #fff;
  color: rgba(38,59,87,100);
}

.btn-secondary {
  background-color: #FF2D55;
  border-color: #FF2D55;
}

.btn-translate-hover {
  transition: all 0.2s ease-in-out;
}

.btn-translate-hover:hover,
.btn-translate-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.alert {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  bottom: 0;
  max-width: 1140px;
  width: 90%;
  z-index: 100;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.navbar {
  padding: 15px 0;
}

.logo {
  cursor: pointer;
}

.nav__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__links li {
  display: inline-block;
  font-weight: 500;
  padding: 0 20px;
}

.nav__links li a {
  color: rgba(255,255,255,1);
}

.nav__links li a:hover  {
  color: rgba(255,255,255,.5);
}

.mobile__menu {
  display: none;
}

.hamburger {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 15px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
}

.hamburger div {
  position: relative;
  width: 25px;
  height: 3px;
  border-radius: 3px;
  background-color: rgba(255,255,255,1);
  margin-top: 6px;
  transition: all 0.3s ease-in-out;
}

#toggle {
  display: none;
}

#toggle:checked + .hamburger .hamburger__top {
  transform: rotate(-45deg);
  margin-top: 12.5px; /* Half the size of hamburger height */
}

#toggle:checked + .hamburger .hamburger__middle {
  transform: rotate(45deg);
  margin-top: -3px; /* Negative value of hamburger div height */
}

#toggle:checked + .hamburger .hamburger__bottom {
  transform: scale(0);
}

#toggle:checked + .hamburger + .hamburger__nav {
  top: 0;
}

.hamburger__nav {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right top, #ff8f56, #fb7a4e, #f7624a, #f24848, #eb2349);
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.hamburger__nav .hamburger__nav--wrapper {
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.hamburger__nav--items {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hamburger__nav--items a {
  margin-top: 40px;
  color: #fff;
  opacity: 0;
  font-size: 2rem;
  transition: all 0.3s ease 0s;
}

.hamburger__nav--items a:first-child {
  margin-top: 0;
}

.hamburger__nav--wrapper nav a {
  opacity: 1;
}

.hamburger__nav--wrapper nav a:hover {
  opacity: 1;
  color: rgba(255,255,255,0.5);
}

.section-lg {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.bg-gradient {
  background-image: linear-gradient(to right top, #ff8f56, #fb7a4e, #f7624a, #f24848, #eb2349);
}

.bg-secondary {
background-color: #EFF2F7 !important;
}

.bg-light {
  background-color: rgba(229,248,247,0.5) !important;
}

.bg-dark {
  background-color: #263B57 !important;
}

.bg-hero {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.bg-hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-image: url(../img/travly-cover-summer.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-hero:after {
  content: "";
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 15px;
  background-image: url(../img/arrow-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bg-feature {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url(../img/travly-feature.jpg);
  background-position: 50% top;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
}

.bg-404 {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(../img/travly-404.jpg);
  background-position: 50% 50%;
  background-size: cover;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.35);
  z-index: -1;
}

.device-chrome {
  position: relative;
  -webkit-box-shadow: 0 0 100px rgba(0,0,0,.07);
  box-shadow: 0 0 100px rgba(0,0,0,.10);
}

.device-chrome:before {
  content: '';
  display: block;
  width: 100%;
  height: 30px;
  background-color: #DDE1E5;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid rgba(215,215,215,0.25);
}

.device-chrome .circle {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 12px 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.device-chrome .circle:first-child {
  background-color: #FF6058;
  left: 10px;
}

.device-chrome .circle:nth-child(2) {
  background-color: #FFBE2F;
  left: 25px;
}

.device-chrome .circle:nth-child(3) {
  background-color: #28CB42;
  left: 40px;
}

.iframe-container {
  width: 100%;
  height: 600px;
  overflow: scroll;
}

.icon-holder img {
  width: 60px;
}

.icon {
  display: inline-flex;
  min-width: 3rem;
  min-height: 3rem;
}

.icon-shape {
  text-align: center;
  border-radius: .25rem;
  align-items: center;
  justify-content: center;
}

.u-sm-avatar {
  width: 4em;
  height: 4rem;
  border: 3px solid #fafafa;
}

.signup {
  max-width: 440px;
}

.form-control {
  height: 60px;
  padding: 15px 15px 15px 48px;
}

.footer {
  background-color: #FF2D55;
  padding: 80px 0;
}

.footer a {
  color: #fff;
}

.social-links li {
  font-size: 1.275rem;
}

/* Blog Styles */

.bg-blog-hero {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url(../img/travly-blog-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.subscribe-icon {
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 10;
}

.blog-content {
  background-color: #EFF2F7;
}

.single-post h3 {
  margin-top: 80px;
}

.single-post p {
  font-size: 1.25rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.single-post p img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.single-post strong {
  color: #263B57;
}

.row.equal-height {
  display: flex;
  flex-wrap: wrap;
}
.row.equal-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.card {
  flex: 1;
}

.card {
  border: none;
  border-radius: .5rem;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.card:hover {
  -webkit-box-shadow: 0 8px 24px 0 rgba(0,0,0,0.08);
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.08);
}

.card:hover .btn {
  background-color: #FF2D55;
  border-color: #FF2D55;
  color: #FFFFFF;
}

.card-title {
  font-size: 20px;
  line-height: 1.5;
}

.card .badge {
  position: absolute;
  top: 20px;
}

.card .author {
  font-size: 14px;
  margin-left: 10px;
}

.card-footer {
  background-color: transparent;
  border-top: none;
  padding: 1.25rem;
}

.btn-post {
  background-color: #FFFFFF;
  border: 1px solid #E0E6ED;
  border-radius: 3px;
  color: #7D8091;
  width: 100%;
  padding: 0.875rem 1rem;
}

.share__menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 80px 20px;
}

.share__menu .share-links li {
  margin: 0 0 10px 0;
}

.share-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-links ul {
  padding: 0 1rem;
}

.share-links li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.share-links li:hover {
  transform: scale(1.2);
}

.share-link--facebook {
  background-color: #3A5999;
}

.share-link--twitter {
  background-color: #56ACEE;
}

.share-link--pocket {
  background-color: #EF4056;
}

.share-link--linkedin {
  background-color: #0077B5;
}

.share-links a {
  color: #FFFFFF;
  font-size: 20px;
}

.share-links:before {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background-color: #E0E6ED;
  margin-top: -15px;
}

.share-links:after {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background-color: #E0E6ED;
  margin-top: -15px;
}

@media screen and (max-width: 1000px) {
  .mobile__menu {
    display: inherit;
  }
  .nav__links, .nav__btn {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .share__menu {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .bg-feature {
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: scroll;
  }

  .alert {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }

  .section-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.85rem;
  }

  .btn-primary {
    font-size: 0.75rem;
  }

  .single-post h3 {
    margin-top: 60px;
  }

  .single-post p {
    font-size: 1rem;
    line-height: inherit;
    margin-bottom: 1.5rem;
  }

  .share-links ul {
    padding: 0 0.5rem;
  }

  .share-links li {
    margin: 0 5px;
  }

  .input-group>.form-control:not(:last-child) {
    width: 100%;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    margin-bottom: 20px;
  }

  .input-group-append {
    width: 100%;
    margin-left: 0;
  }

  .input-group>.input-group-append>.btn {
    width: inherit;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
}
